import React from "react"

import Background from "../components/background"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/bootstrap/container"
import PageTitle from "../components/page-title"
import GridLines from "../components/gridlines"
import { Link } from "gatsby"

const RegisterThankYouPage = () => (
  <Background>
    <Layout>
      <SEO title="Thank you" />
      <Container>
        <PageTitle text="Thank You" />

        <GridLines gridColor="#d23464">
          <div style={{
            fontFamily: `Din Pro`,
            fontWeight: `400`,
            fontSize: `1.5rem`,
            textAlign: `center`,
            color: `#fff`,
            background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`,
            padding: `2rem`,
            margin: `0`
          }}>
            <p>Your registration was successful.<br />You will receive joining details for the event in the coming weeks.</p>
            <Link to="/" className="btn btn-primary" style={{
                backgroundColor: `rgb(255, 255, 255)`,
                borderColor: `rgb(255, 255, 255)`,
                color: `rgb(210, 52, 100)`,
                textTransform: `uppercase`,
                borderRadius: 0,
                padding: `0.6rem 1rem`,
                fontWeight: 600,
              }}>Continue</Link>
          </div>
        </GridLines>
        <br /><br /><br /><br /><br />
      </Container>
    </Layout>
  </Background>
)

export default RegisterThankYouPage
